import React from 'react';
import Helmet from 'react-helmet';

import BetaForm from '../components/BetaForm';

import appstore from '../img/appstore.svg';
import googleplay from '../img/googleplay.svg';

export const BetaTemplate = () => (
  <div className="beta">
    <Helmet>
      <link rel="stylesheet" href="https://use.typekit.net/qgp0pti.css" />
    </Helmet>
    <section>
      <h1>Squad Rivals</h1>

      <article>
        <h2>Sign up for the closed beta</h2>
        <p>Collect hundreds of characters with unique abilities, and squad up to fend off your enemies in the sleepy town of Molasses. From office drones to celebrity-level YouTubers, you’ll build and deploy your collection to defeat the residents and their Bosses. Experimenting with different combinations of your squad will earn you game-changing bonuses and help you defeat your Squad Rivals!</p>

        <h3>About the closed beta</h3>
        <p>We want to hear your feedback about Squad Rivals before it hits the masses. Give it a play, have fun, and flip us a note!</p>

        <h4>The Closed Beta opens January, 2018.</h4>
        <h5>*Sign up below for a chance to participate*</h5>
      </article>

      <BetaForm />
    </section>
    <footer>
      <ul class="social">
        <li><img src={appstore} /></li>
        <li><img src={googleplay} /></li>
      </ul>

      <ul class="toc">
        <li><a href="//bbtv.com/copyright-policy" target="_blank">Copyright</a></li>
        <li><a href="//bbtv.com/terms-of-service" target="_blank">Terms and Conditions</a></li>
        <li><a href="//bbtv.com/privacy-policy" target="_blank">Privacy Policy</a></li>
      </ul>
    </footer>
  </div>
)

export default () => {
  return (
    <BetaTemplate />
  )
}
