import React from "react";

function encode(data) {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
}

export default class BetaForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false
    };
  }

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  }

  handleSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...this.state })
    })
      .then(() => this.setState({success: true}))
      .catch(error => alert(error));

    e.preventDefault();
  };

  render() {
    return (
      <form
        name="contact"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={this.handleSubmit}>
        <p hidden>
          <label>
            Don’t fill this out: <input name="bot-field" onChange={this.handleChange} />
          </label>
        </p>

        <fieldset hidden={this.state.success}>
          <div className="form-wrapper">
            <label>Email</label>
            <input className="email fsField" type="email" name="email" placeholder="Email*" onChange={this.handleChange} />
          </div>

          <div className="form-wrapper">
            <label>Device</label>
            <select name="device" className="fsField select-box" onChange={this.handleChange} >
              <option name="Empty" value="empty">Please choose your device...</option>
              <option name="iPhone" value="iphone">iPhone</option>
              <option name="iPad" value="ipad">iPad</option>
              <option name="iPod Touch" value="ipod_touch">iPod Touch</option>
              <option name="Android Phone" value="android_phone">Android Phone</option>
              <option name="Android Tablet" value="android_tablet">Android Tablet</option>
            </select>
            <div className="iphone" hidden={this.state.device === 'android_phone' || this.state.device === 'android_tablet'}>
              <em>
                Compatibility: Requires iOS 7.0 or later. <br /><br />
                Compatible with iPhone, iPad and iPod touch.
              </em>
            </div>
            <div className="android" hidden={!this.state.device || this.state.device === 'empty' || this.state.device === 'iphone' || this.state.device === 'ipad' || this.state.device === 'ipod_touch'}>
              <em>Android 4.1 or higher required.</em>
            </div>
          </div>

          <div className="form-wrapper">
            <label>Country</label>
            <select name="country" className="fsField select-box" onChange={this.handleChange} >
              <option name="Empty" value="empty">Please choose your country...</option>
              <option name="Afghanistan" value="Afghanistan">Afghanistan</option>
              <option name="Albania" value="Albania">Albania</option>
              <option name="Algeria" value="Algeria">Algeria</option>
              <option name="American Samoa" value="American Samoa">American Samoa</option>
              <option name="Andorra" value="Andorra">Andorra</option>
              <option name="Angola" value="Angola">Angola</option>
              <option name="Anguilla" value="Anguilla">Anguilla</option>
              <option name="Antartica" value="Antartica">Antarctica</option>
              <option name="Antigua and Barbuda" value="Antigua and Barbuda">Antigua and Barbuda</option>
              <option name="Argentina" value="Argentina">Argentina</option>
              <option name="Armenia" value="Armenia">Armenia</option>
              <option name="Aruba" value="Aruba">Aruba</option>
              <option name="Australia" value="Australia">Australia</option>
              <option name="Austria" value="Austria">Austria</option>
              <option name="Azerbaijan" value="Azerbaijan">Azerbaijan</option>
              <option name="Bahamas" value="Bahamas">Bahamas</option>
              <option name="Bahrain" value="Bahrain">Bahrain</option>
              <option name="Bangladesh" value="Bangladesh">Bangladesh</option>
              <option name="Barbados" value="Barbados">Barbados</option>
              <option name="Belarus" value="Belarus">Belarus</option>
              <option name="Belgium" value="Belgium">Belgium</option>
              <option name="Belize" value="Belize">Belize</option>
              <option name="Benin" value="Benin">Benin</option>
              <option name="Bermuda" value="Bermuda">Bermuda</option>
              <option name="Bhutan" value="Bhutan">Bhutan</option>
              <option name="Bolivia" value="Bolivia">Bolivia</option>
              <option name="Bosnia and Herzegowina" value="Bosnia and Herzegowina">Bosnia and Herzegowina</option>
              <option name="Botswana" value="Botswana">Botswana</option>
              <option name="Bouvet Island" value="Bouvet Island">Bouvet Island</option>
              <option name="Brazil" value="Brazil">Brazil</option>
              <option name="British Indian Ocean Territory" value="British Indian Ocean Territory">British Indian Ocean Territory</option>
              <option name="Brunei Darussalam" value="Brunei Darussalam">Brunei Darussalam</option>
              <option name="Bulgaria" value="Bulgaria">Bulgaria</option>
              <option name="Burkina Faso" value="Burkina Faso">Burkina Faso</option>
              <option name="Burundi" value="Burundi">Burundi</option>
              <option name="Cambodia" value="Cambodia">Cambodia</option>
              <option name="Cameroon" value="Cameroon">Cameroon</option>
              <option name="Canada" value="Canada">Canada</option>
              <option name="Cape Verde" value="Cape Verde">Cape Verde</option>
              <option name="Cayman Islands" value="Cayman Islands">Cayman Islands</option>
              <option name="Central African Republic" value="Central African Republic">Central African Republic</option>
              <option name="Chad" value="Chad">Chad</option>
              <option name="Chile" value="Chile">Chile</option>
              <option name="China" value="China">China</option>
              <option name="Christmas Island" value="Christmas Island">Christmas Island</option>
              <option name="Cocos Islands" value="Cocos Islands">Cocos (Keeling) Islands</option>
              <option name="Colombia" value="Colombia">Colombia</option>
              <option name="Comoros" value="Comoros">Comoros</option>
              <option name="Congo" value="Congo">Congo</option>
              <option name="Congo" value="Congo">Congo, the Democratic Republic of the</option>
              <option name="Cook Islands" value="Cook Islands">Cook Islands</option>
              <option name="Costa Rica" value="Costa Rica">Costa Rica</option>
              <option name="Cota D'Ivoire" value="Cota D'Ivoire">Cote d'Ivoire</option>
              <option name="Croatia" value="Croatia">Croatia (Hrvatska)</option>
              <option name="Cuba" value="Cuba">Cuba</option>
              <option name="Cyprus" value="Cyprus">Cyprus</option>
              <option name="Czech Republic" value="Czech Republic">Czech Republic</option>
              <option name="Denmark" value="Denmark">Denmark</option>
              <option name="Djibouti" value="Djibouti">Djibouti</option>
              <option name="Dominica" value="Dominica">Dominica</option>
              <option name="Dominican Republic" value="Dominican Republic">Dominican Republic</option>
              <option name="East Timor" value="East Timor">East Timor</option>
              <option name="Ecuador" value="Ecuador">Ecuador</option>
              <option name="Egypt" value="Egypt">Egypt</option>
              <option name="El Salvador" value="El Salvador">El Salvador</option>
              <option name="Equatorial Guinea" value="Equatorial Guinea">Equatorial Guinea</option>
              <option name="Eritrea" value="Eritrea">Eritrea</option>
              <option name="Estonia" value="Estonia">Estonia</option>
              <option name="Ethiopia" value="Ethiopia">Ethiopia</option>
              <option name="Falkland Islands" value="Falkland Islands">Falkland Islands (Malvinas)</option>
              <option name="Faroe Islands" value="Faroe Islands">Faroe Islands</option>
              <option name="Fiji" value="Fiji">Fiji</option>
              <option name="Finland" value="Finland">Finland</option>
              <option name="France" value="France">France</option>
              <option name="France Metropolitan" value="France Metropolitan">France, Metropolitan</option>
              <option name="French Guiana" value="French Guiana">French Guiana</option>
              <option name="French Polynesia" value="French Polynesia">French Polynesia</option>
              <option name="French Southern Territories" value="French Southern Territories">French Southern Territories</option>
              <option name="Gabon" value="Gabon">Gabon</option>
              <option name="Gambia" value="Gambia">Gambia</option>
              <option name="Georgia" value="Georgia">Georgia</option>
              <option name="Germany" value="Germany">Germany</option>
              <option name="Ghana" value="Ghana">Ghana</option>
              <option name="Gibraltar" value="Gibraltar">Gibraltar</option>
              <option name="Greece" value="Greece">Greece</option>
              <option name="Greenland" value="Greenland">Greenland</option>
              <option name="Grenada" value="Grenada">Grenada</option>
              <option name="Guadeloupe" value="Guadeloupe">Guadeloupe</option>
              <option name="Guam" value="Guam">Guam</option>
              <option name="Guatemala" value="Guatemala">Guatemala</option>
              <option name="Guinea" value="Guinea">Guinea</option>
              <option name="Guinea-Bissau" value="Guinea-Bissau">Guinea-Bissau</option>
              <option name="Guyana" value="Guyana">Guyana</option>
              <option name="Haiti" value="Haiti">Haiti</option>
              <option name="Heard and McDonald Islands" value="Heard and McDonald Islands">Heard and Mc Donald Islands</option>
              <option name="Holy See" value="Holy See">Holy See (Vatican City State)</option>
              <option name="Honduras" value="Honduras">Honduras</option>
              <option name="Hong Kong" value="Hong Kong">Hong Kong</option>
              <option name="Hungary" value="Hungary">Hungary</option>
              <option name="Iceland" value="Iceland">Iceland</option>
              <option name="India" value="India">India</option>
              <option name="Indonesia" value="Indonesia">Indonesia</option>
              <option name="Iran" value="Iran">Iran (Islamic Republic of)</option>
              <option name="Iraq" value="Iraq">Iraq</option>
              <option name="Ireland" value="Ireland">Ireland</option>
              <option name="Israel" value="Israel">Israel</option>
              <option name="Italy" value="Italy">Italy</option>
              <option name="Jamaica" value="Jamaica">Jamaica</option>
              <option name="Japan" value="Japan">Japan</option>
              <option name="Jordan" value="Jordan">Jordan</option>
              <option name="Kazakhstan" value="Kazakhstan">Kazakhstan</option>
              <option name="Kenya" value="Kenya">Kenya</option>
              <option name="Kiribati" value="Kiribati">Kiribati</option>
              <option name="Democratic People's Republic of Korea" value="Democratic People's Republic of Korea">Korea, Democratic People's Republic of</option>
              <option name="Korea" value="Korea">Korea, Republic of</option>
              <option name="Kuwait" value="Kuwait">Kuwait</option>
              <option name="Kyrgyzstan" value="Kyrgyzstan">Kyrgyzstan</option>
              <option name="Lao" value="Lao">Lao People's Democratic Republic</option>
              <option name="Latvia" value="Latvia">Latvia</option>
              <option name="Lebanon" value="Lebanon">Lebanon</option>
              <option name="Lesotho" value="Lesotho">Lesotho</option>
              <option name="Liberia" value="Liberia">Liberia</option>
              <option name="Libyan Arab Jamahiriya" value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
              <option name="Liechtenstein" value="Liechtenstein">Liechtenstein</option>
              <option name="Lithuania" value="Lithuania">Lithuania</option>
              <option name="Luxembourg" value="Luxembourg">Luxembourg</option>
              <option name="Macau" value="Macau">Macau</option>
              <option name="Macedonia" value="Macedonia">Macedonia, The Former Yugoslav Republic of</option>
              <option name="Madagascar" value="Madagascar">Madagascar</option>
              <option name="Malawi" value="Malawi">Malawi</option>
              <option name="Malaysia" value="Malaysia">Malaysia</option>
              <option name="Maldives" value="Maldives">Maldives</option>
              <option name="Mali" value="Mali">Mali</option>
              <option name="Malta" value="Malta">Malta</option>
              <option name="Marshall Islands" value="Marshall Islands">Marshall Islands</option>
              <option name="Martinique" value="Martinique">Martinique</option>
              <option name="Mauritania" value="Mauritania">Mauritania</option>
              <option name="Mauritius" value="Mauritius">Mauritius</option>
              <option name="Mayotte" value="Mayotte">Mayotte</option>
              <option name="Mexico" value="Mexico">Mexico</option>
              <option name="Micronesia" value="Micronesia">Micronesia, Federated States of</option>
              <option name="Moldova" value="Moldova">Moldova, Republic of</option>
              <option name="Monaco" value="Monaco">Monaco</option>
              <option name="Mongolia" value="Mongolia">Mongolia</option>
              <option name="Montserrat" value="Montserrat">Montserrat</option>
              <option name="Morocco" value="Morocco">Morocco</option>
              <option name="Mozambique" value="Mozambique">Mozambique</option>
              <option name="Myanmar" value="Myanmar">Myanmar</option>
              <option name="Namibia" value="Namibia">Namibia</option>
              <option name="Nauru" value="Nauru">Nauru</option>
              <option name="Nepal" value="Nepal">Nepal</option>
              <option name="Netherlands" value="Netherlands">Netherlands</option>
              <option name="Netherlands Antilles" value="Netherlands Antilles">Netherlands Antilles</option>
              <option name="New Caledonia" value="New Caledonia">New Caledonia</option>
              <option name="New Zealand" value="New Zealand">New Zealand</option>
              <option name="Nicaragua" value="Nicaragua">Nicaragua</option>
              <option name="Niger" value="Niger">Niger</option>
              <option name="Nigeria" value="Nigeria">Nigeria</option>
              <option name="Niue" value="Niue">Niue</option>
              <option name="Norfolk Island" value="Norfolk Island">Norfolk Island</option>
              <option name="Northern Mariana Islands" value="Northern Mariana Islands">Northern Mariana Islands</option>
              <option name="Norway" value="Norway">Norway</option>
              <option name="Oman" value="Oman">Oman</option>
              <option name="Pakistan" value="Pakistan">Pakistan</option>
              <option name="Palau" value="Palau">Palau</option>
              <option name="Panama" value="Panama">Panama</option>
              <option name="Papua New Guinea" value="Papua New Guinea">Papua New Guinea</option>
              <option name="Paraguay" value="Paraguay">Paraguay</option>
              <option name="Peru" value="Peru">Peru</option>
              <option name="Philippines" value="Philippines">Philippines</option>
              <option name="Pitcairn" value="Pitcairn">Pitcairn</option>
              <option name="Poland" value="Poland">Poland</option>
              <option name="Portugal" value="Portugal">Portugal</option>
              <option name="Puerto Rico" value="Puerto Rico">Puerto Rico</option>
              <option name="Qatar" value="Qatar">Qatar</option>
              <option name="Reunion" value="Reunion">Reunion</option>
              <option name="Romania" value="Romania">Romania</option>
              <option name="Russia" value="Russia">Russian Federation</option>
              <option name="Rwanda" value="Rwanda">Rwanda</option>
              <option name="Saint Kitts and Nevis" value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
              <option name="Saint LUCIA" value="Saint LUCIA">Saint LUCIA</option>
              <option name="Saint Vincent" value="Saint Vincent">Saint Vincent and the Grenadines</option>
              <option name="Samoa" value="Samoa">Samoa</option>
              <option name="San Marino" value="San Marino">San Marino</option>
              <option name="Sao Tome and Principe" value="Sao Tome and Principe">Sao Tome and Principe</option>
              <option name="Saudi Arabia" value="Saudi Arabia">Saudi Arabia</option>
              <option name="Senegal" value="Senegal">Senegal</option>
              <option name="Seychelles" value="Seychelles">Seychelles</option>
              <option name="Sierra" value="Sierra">Sierra Leone</option>
              <option name="Singapore" value="Singapore">Singapore</option>
              <option name="Slovakia" value="Slovakia">Slovakia (Slovak Republic)</option>
              <option name="Slovenia" value="Slovenia">Slovenia</option>
              <option name="Solomon Islands" value="Solomon Islands">Solomon Islands</option>
              <option name="Somalia" value="Somalia">Somalia</option>
              <option name="South Africa" value="South Africa">South Africa</option>
              <option name="South Georgia" value="South Georgia">South Georgia and the South Sandwich Islands</option>
              <option name="Span" value="Span">Spain</option>
              <option name="SriLanka" value="SriLanka">Sri Lanka</option>
              <option name="St. Helena" value="St. Helena">St. Helena</option>
              <option name="St. Pierre and Miguelon" value="St. Pierre and Miguelon">St. Pierre and Miquelon</option>
              <option name="Sudan" value="Sudan">Sudan</option>
              <option name="Suriname" value="Suriname">Suriname</option>
              <option name="Svalbard" value="Svalbard">Svalbard and Jan Mayen Islands</option>
              <option name="Swaziland" value="Swaziland">Swaziland</option>
              <option name="Sweden" value="Sweden">Sweden</option>
              <option name="Switzerland" value="Switzerland">Switzerland</option>
              <option name="Syria" value="Syria">Syrian Arab Republic</option>
              <option name="Taiwan" value="Taiwan">Taiwan, Province of China</option>
              <option name="Tajikistan" value="Tajikistan">Tajikistan</option>
              <option name="Tanzania" value="Tanzania">Tanzania, United Republic of</option>
              <option name="Thailand" value="Thailand">Thailand</option>
              <option name="Togo" value="Togo">Togo</option>
              <option name="Tokelau" value="Tokelau">Tokelau</option>
              <option name="Tonga" value="Tonga">Tonga</option>
              <option name="Trinidad and Tobago" value="Trinidad and Tobago">Trinidad and Tobago</option>
              <option name="Tunisia" value="Tunisia">Tunisia</option>
              <option name="Turkey" value="Turkey">Turkey</option>
              <option name="Turkmenistan" value="Turkmenistan">Turkmenistan</option>
              <option name="Turks and Caicos" value="Turks and Caicos">Turks and Caicos Islands</option>
              <option name="Tuvalu" value="Tuvalu">Tuvalu</option>
              <option name="Uganda" value="Uganda">Uganda</option>
              <option name="Ukraine" value="Ukraine">Ukraine</option>
              <option name="United Arab Emirates" value="United Arab Emirates">United Arab Emirates</option>
              <option name="United Kingdom" value="United Kingdom">United Kingdom</option>
              <option name="United States" value="United States">United States</option>
              <option name="United States Minor Outlying Islands" value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
              <option name="Uruguay" value="Uruguay">Uruguay</option>
              <option name="Uzbekistan" value="Uzbekistan">Uzbekistan</option>
              <option name="Vanuatu" value="Vanuatu">Vanuatu</option>
              <option name="Venezuela" value="Venezuela">Venezuela</option>
              <option name="Vietnam" value="Vietnam">Viet Nam</option>
              <option name="Virgin Islands (British)" value="Virgin Islands (British)">Virgin Islands (British)</option>
              <option name="Virgin Islands (U.S)" value="Virgin Islands (U.S)">Virgin Islands (U.S.)</option>
              <option name="Wallis and Futana Islands" value="Wallis and Futana Islands">Wallis and Futuna Islands</option>
              <option name="Western Sahara" value="Western Sahara">Western Sahara</option>
              <option name="Yemen" value="Yemen">Yemen</option>
              <option name="Yugoslavia" value="Yugoslavia">Yugoslavia</option>
              <option name="Zambia" value="Zambia">Zambia</option>
              <option name="Zimbabwe" value="Zimbabwe">Zimbabwe</option>
            </select>
          </div>

          <div className="form-wrapper">
            <label className="small">How did you hear about the closed beta?</label>
            <select name="heardFrom" className="fsField select-box" onChange={this.handleChange} >
              <option name="Empty" value="empty">Please choose...</option>
              <option name="Chad Wild Clay" value="Chad Wild Clay">Chad Wild Clay</option>
              <option name="Bootramp" value="Bootramp">Bootramp</option>
              <option name="News Story" value="News Story">News Story</option>
              <option name="From a Friend" value="From a Friend">From a Friend</option>
              <option name="Other" value="Other">Other</option>
            </select>
          </div>

          <div className="center">
            <button
              type="submit"
              className={!this.state.email || !this.state.device || !this.state.country || !this.state.heardFrom ? 'submit disabled' : 'submit'}>Sign Up!</button>
          </div>
        </fieldset>
        <h2 className="success" hidden={!this.state.success}>Thanks for signing up! If selected, you will receive an email with more information prior to beta launch.</h2>
      </form>
    );
  }
}
